export default {
  id: '9da749f8ec',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page form-page">\r\n      <div class="navbar paymentPage no-shadow">\r\n        <div class="navbar-bg"></div>\r\n        <div class="navbar-inner">\r\n          <div class="left">\r\n            <a href="#" class="link back">\r\n              <i class="material-icons">arrow_back</i>\r\n            </a>\r\n          </div>\r\n          <div class="title">Play Games</div>\r\n        </div>\r\n      </div>\r\n    <div class="page-content paymentPage">\r\n        <div class="margin-top">\r\n          <div id="game-container"></div>\r\n        </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
import $$ from 'dom7';

import HomePage from '../pages/home.f7.html';
import GamePage from '../pages/games.f7.html';
import VipPage from '../pages/vip.f7.html';
import NotFoundPage from '../pages/404.f7.html';
import party from "party-js";
var baseURL = "https://doubledragon.in/";

var routes = [
  {
    path: '/',
    component: HomePage,
    name: 'Home',
    on: {
      pageInit: function (e, page) {
        var app = this.app;
        //get helpline Number
        // app.methods.getSettings(function(data) {
        //   $$('.helpLinenumber').html(data.help);
        // });
        $.get(baseURL+'settings.json', function(data) {
          $$('.wa-support').attr('href', data.wa_number);
          $$('.tele-support').attr('href', data.telegram);
          $$('.game-link').attr('href', data.game_link);
          if(data.is_ig == true) {
            $$('.ig-container').removeClass('display-none');
            $$('.ig-container2').removeClass('display-none');
            $$('.ig-container img').attr('src', 'assets/banners/01.jpg');
            $$('.ig-container2 img').attr('src', 'assets/banners/02.jpg');
            $$('.ig-container a').attr('href', data.ig_url);
            $$('.ig-container2 a').attr('href', data.ig_url);
          }
        });
        //swiper slider
        var swiper = app.swiper.create('.swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 0,
          centeredSlides: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        });
        $$('.play-btn').on('click', function() {
          if(!app.device.ios || !app.device.iphone || !app.device.ipad || !app.device.iphoneX || !app.device.macos) {
            window.location.href = 'https://doubledragon.in/android/doubledragon.apk';
          }
        });
        $$('.play-games').on('click', function() {
          var game_link = $(this).attr('data-link');
          localStorage.setItem('game_link', game_link);
          app.views.main.router.navigate('/games/');
        });
        $$('.btn-vip').on('click', function() {
          app.views.main.router.navigate('/vip/');
        });
      }
    }
  },
  {
    //games page
    path: '/games/',
    component: GamePage,
    name: 'Games',
    on: {
      pageInit: function (e, page) {
        var app = this.app;
        //get game link
        const gameUrl = localStorage.getItem('game_link');
        const iframe = document.createElement('iframe');
        var deviceHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        iframe.src = gameUrl;
        iframe.width = "100%";
        iframe.frameBorder = "0";
        iframe.allowFullscreen = true;
        iframe.style.height = deviceHeight + 'px';

        // Append the iframe to the game container
        document.getElementById('game-container').appendChild(iframe);
      }
    }
  },
  {
    //games page
    path: '/vip/',
    component: VipPage,
    name: 'VIP',
    on: {
      pageInit: function (e, page) {
        var app = this.app;
        $.get(baseURL+'settings.json', function(data) {
          if(data.is_ig == true) {
            $$('.ig-container a').attr('href', data.ig_url);
          }
        });
      }
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
    on: {
      pageInit: function() {
        $$('.url').html(window.location);
      }
    }
  },
];

export default routes;